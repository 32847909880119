import React from "react"
import { Link } from "gatsby"
//@ts-ignore
import Icon from "../../logos/list.svg"

const Nav = () => {
  return (
    <>
      <div className="absolute top-0 z-50 w-full text-white px-4 sm:px-6 lg:px-8 gradient font-sans">
        <nav className="flex items-center justify-between sm:h-10 lg:justify-start">
          <div className="flex items-center flex-grow flex-shrink-0 lg:flex-grow-0">
            <div className="flex  items-center justify-between w-full md:w-auto">
              <a href="/" aria-label="Home">
                <svg
                  className="h-6 w-6 ping"
                  version="1.2"
                  baseProfile="tiny"
                  xmlns="http://www.w3.org/2000/svg"
                  width="36"
                  height="36"
                  viewBox="0 0 24 24"
                >
                  <g>
                    <path d="M17 21h-10c-1.654 0-3-1.346-3-3v-12c0-1.654 1.346-3 3-3h10c1.654 0 3 1.346 3 3v12c0 1.654-1.346 3-3 3zm-10-16c-.551 0-1 .449-1 1v12c0 .551.449 1 1 1h10c.551 0 1-.449 1-1v-12c0-.551-.449-1-1-1h-10zM16 11h-8c-.276 0-.5-.224-.5-.5s.224-.5.5-.5h8c.276 0 .5.224.5.5s-.224.5-.5.5zM16 8h-8c-.276 0-.5-.224-.5-.5s.224-.5.5-.5h8c.276 0 .5.224.5.5s-.224.5-.5.5zM16 14h-8c-.276 0-.5-.224-.5-.5s.224-.5.5-.5h8c.276 0 .5.224.5.5s-.224.5-.5.5zM16 17h-8c-.276 0-.5-.224-.5-.5s.224-.5.5-.5h8c.276 0 .5.224.5.5s-.224.5-.5.5z" />
                  </g>
                </svg>
              </a>
            </div>
          </div>
          <div className="block md:ml-10 md:pr-4 w-full flex flex-row-reverse">
            <Link
              to="/pricing?nav"
              className="ml-8 underline font-medium hover:text-gray-900 transition duration-150 ease-in-out"
            >
              Pricing
            </Link>
            <Link
              to="/resources/"
              className="ml-8 font-medium text-sm hover:text-gray-900 transition duration-150 ease-in-out"
            >
              Resources
            </Link>
          </div>
        </nav>
      </div>
    </>
  )
}

export default Nav
