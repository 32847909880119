/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql, Link } from "gatsby"

import "./layout.css"
import Header from "./header"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <Header siteTitle={data.site.siteMetadata.title} />
      <div
        style={{
          margin: `1rem auto 0 auto`,
          padding: `0`,
        }}
      >
        <main>{children}</main>
        <footer className="w-full bg-black content-center flex text-white  font-sans items-center">
          <div>
            <p className="text-white pt-4 pl-4 content-center align-center font-sans">
              <svg
                className="h-3 w-3 spin inline mr-2"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="white"
              >
                <path d="M10 20a10 10 0 1 1 0-20 10 10 0 0 1 0 20zM7.88 7.88l-3.54 7.78 7.78-3.54 3.54-7.78-7.78 3.54zM10 11a1 1 0 1 1 0-2 1 1 0 0 1 0 2z" />
              </svg>
              For questions and inquiries e-mail:{" "}
              <code>hey@theinfluencerlist.co</code>
            </p>
          </div>
          <div className="mx-auto flex lg:flex-row flex-col">
            <Link className="underline" to="/privacy-policy/">
              Privacy Policy
            </Link>
            <Link className="underline" to="/about-us/">
              About Us
            </Link>
          </div>
        </footer>
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
