import React, { useState } from "react"
import CTA from "../components/cta"
import Layout from "../components/layout"
import { loadStripe } from "@stripe/stripe-js"
import GuaranteeBadge from "../components/guarantee-badge"
import CustomListForm from "../components/forms/customList"
import { Link } from "gatsby"

let stripePromise
const getStripe = () => {
  if (!stripePromise) {
    stripePromise = loadStripe(
      "pk_live_51JYY4cGlZNC0CUqJD1FCCX91Qp0Yh2rsTYxr3ID7aDJZq7ZMY07bgDIbnw4oHY5A7oUHhFNPhIzzZH6gBZvI13BB004Ysj2Jrz"
    )
  }
  return stripePromise
}

const CustomList = () => {
  const faq = [
    {
      q: "How does it work?",
      a:
        "Once you've purchased a list you will get a link to a google data sheet as well as a download link in your e-mail that you can use to download the file. Note that it can take up to 24hours for the link to generate.",
    },
    {
      q: "Do you work with the influencers on the list?",
      a:
        "We don't have any relationship with the influencers on theinfluencerlist. They are chosen based on their content and metrics alone.",
    },
    {
      q: "Why do you only release a list every 3 months?",
      a:
        "By comparing the list in a 3 month interval we get a more consistent tracking of their growth forecast.",
    },
    {
      q: "Why do some columns have the word (SAMPLE) added?",
      a:
        "We have the sample tag to note that the data in that column isn't for the entire lifespan of the account, but for a specific timespan. This is to ensure that the data we show is as much an accurate representation of the influencers current data as possible. And not their historical data.",
    },
    {
      q: "Why does the latest cost more than the previous version?",
      a:
        "In addition to updating all the previous influencers we also add ~20% more influencers each new version.",
    },
    {
      q: "How do i contact influencers when I'm done searching?",
      a:
        "We recommend you reach out to them personally on DM's on the respective platform introducing yourself, to give it a personal touch.",
    },
    {
      q: "I want a refund",
      a: "Contact us at hey@theinfluencerlist.co",
    },
    {
      q: "I got scammed!",
      a:
        "We are sorry to hear that. While we don't have a preexisting releationship with the influencers in our list fraudalent behaviour is not something we tolerate on our list. We can't do anything about your situation but we can stop it from happening to someone else. Please contact us at email@mail.com with details of who it was.",
    },
    {
      q: "I don't want my profile listed on theinfluencerlist",
      a:
        "No worries! Contact us with your profile details and we will remove you.",
    },
    {
      q: "I have a question that isn't answered here",
      a:
        "We are happy to answer all and any questions you have. Reach out to us on hey@theinfluencerlist.co",
    },
  ]

  const stripeCheckout = async (
    event,
    code = "price_1JkshKGlZNC0CUqJF0emwbTR"
  ) => {
    event.preventDefault()

    const stripe = await getStripe()

    const { error } = await stripe.redirectToCheckout({
      mode: "payment",
      lineItems: [{ price: "price_1JkshKGlZNC0CUqJF0emwbTR", quantity: 1 }],
      successUrl: `http://www.theinfluencerlist.co/success`,
      cancelUrl: `http://www.theinfluencerlist.co/cancel`,
    })

    if (error) {
      alert(`An error occured ${error.message}`)
    }
  }

  function gtag_report_conversion(url) {
    if (typeof window !== "undefined") {
      var callback = function () {
        if (typeof url != "undefined") {
          window.location = url
        }
      }
      window.gtag("event", "conversion", {
        send_to: "AW-614082583/QpTfCPX_3f0CEJfQ6KQC",
        event_callback: callback,
      })
      return false
    }
  }

  return (
    <Layout>
      <div className="pb-8 map">
        <div className="">
          <header className="text-center font-sans max-w-2xl px-4 sm:px-3 mx-auto pt-16 sm:pt-24 xl:pt-32 pb-16 sm:pb-24 md:pb-20 xl:pb-28">
            <h1 className="md:text-7xl sm:text-5.5xl font-extrabold tracking-tight mb-4">
              Start your Influencer Marketing Journey
            </h1>
            <p className="text-lg max-w-xl mx-auto mb-8">
              Find micro influencers on your terms. Create a{" "}
              <span className="underline italic">personalized</span> list of
              influencers that align with your brand.
            </p>
            <p className="text-green font-semibold font-sans">
              <Link to="https://omnifluence-app.web.app/sign-up/sample">
                Sign up and access 500 instagram influencers for free!{" "}
              </Link>
            </p>
          </header>
          <section>
            <div className="py-12 ">
              <div className="max-w-screen-xl mx-auto px-4 sm:px-6 lg:px-8">
                <div className="lg:text-center">
                  <h3 className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl sm:leading-10">
                    From List to Action
                  </h3>
                  <p className="mt-4 max-w-2xl text-xl leading-7 text-gray-700 lg:mx-auto">
                    Don't spend days searching through social media only to find
                    a handful of influencers that don't even impact your brand.
                    With our highly customizable lists you can find the right
                    influencers for your business.
                  </p>
                </div>

                <div className="mt-10">
                  <p className="text-base text-center my-16 leading-6 text-green font-semibold tracking-wide uppercase">
                    Features
                  </p>
                  <ul className="md:grid md:grid-cols-2 md:col-gap-8 md:row-gap-10">
                    <li>
                      <div className="flex">
                        <div className="flex-shrink-0">
                          <div className="flex items-center justify-center h-12 w-12 rounded-md bg-dark-green text-white">
                            <svg
                              className="h-6 w-6"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                            >
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                                d="M3 6l3 1m0 0l-3 9a5.002 5.002 0 006.001 0M6 7l3 9M6 7l6-2m6 2l3-1m-3 1l-3 9a5.002 5.002 0 006.001 0M18 7l3 9m-3-9l-6-2m0-2v2m0 16V5m0 16H9m3 0h3"
                              />
                            </svg>
                          </div>
                        </div>
                        <div className="ml-4">
                          <h4 className="text-lg leading-6 font-medium text-gray-900">
                            Data & metrics!
                          </h4>
                          <p className="mt-2 text-base leading-6 text-gray-500">
                            Focus on the data that matters. We collect data that
                            helps you make a informed decision about each
                            influencers. With the freedom to expand with any
                            additional data that you want.
                          </p>
                        </div>
                      </div>
                    </li>
                    <li className="mt-10 md:mt-0">
                      <div className="flex">
                        <div className="flex-shrink-0">
                          <div className="flex items-center justify-center h-12 w-12 rounded-md bg-dark-green text-white">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 20 20"
                              className="h-6 w-6"
                              stroke="none"
                              fill="currentColor"
                            >
                              <path d="M2 19H1V1h18v18H2zm1-2h14V3H3v14zm10-8h2v2h-2V9zM9 9h2v2H9V9zM5 9h2v2H5V9zm4-4h2v2H9V5zm0 8h2v2H9v-2z" />
                            </svg>
                          </div>
                        </div>
                        <div className="ml-4">
                          <h4 className="text-lg leading-6 font-medium text-gray-900">
                            Tailored to your needs
                          </h4>
                          <p className="mt-2 text-base leading-6 text-gray-500">
                            You decide what influencers you want to work with,
                            we'll find them for you and present in an easy to
                            understand list.
                          </p>
                        </div>
                      </div>
                    </li>
                    <li className="mt-10 md:mt-0">
                      <div className="flex">
                        <div className="flex-shrink-0">
                          <div className="flex items-center justify-center h-12 w-12 rounded-md bg-dark-green text-white">
                            <svg
                              className="h-6 w-6"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                            >
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                                d="M13 10V3L4 14h7v7l9-11h-7z"
                              />
                            </svg>
                          </div>
                        </div>
                        <div className="ml-4">
                          <h4 className="text-lg leading-6 font-medium text-gray-900">
                            Save time
                          </h4>
                          <p className="mt-2 text-base leading-6 text-gray-500">
                            Spend your time where it matters, leave finding and
                            researching influencers to us and you can spend more
                            time doing what you want.
                          </p>
                        </div>
                      </div>
                    </li>
                    <li className="mt-10 md:mt-0">
                      <div className="flex">
                        <div className="flex-shrink-0">
                          <div className="flex items-center justify-center h-12 w-12 rounded-md bg-dark-green text-white">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className="h-6 w-6"
                              viewBox="0 0 20 20"
                              stroke="currentColor"
                              fill="none"
                            >
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="1"
                                d="M13 7v11a2 2 0 0 1-2 2H9a2 2 0 0 1-2-2V7L5 5V3h10v2l-2 2zM9 8v1a1 1 0 1 0 2 0V8a1 1 0 0 0-2 0zM5 0h10v2H5V0z"
                              />
                            </svg>
                          </div>
                        </div>
                        <div className="ml-4">
                          <h4 className="text-lg leading-6 font-medium text-gray-900">
                            Deep dive & segment
                          </h4>
                          <p className="mt-2 text-base leading-6 text-gray-500">
                            You can easily dig even deeper into a sub group of
                            your audience to find influencers that are active
                            within markets you want to expand to or focus on a
                            particular sub-niche or country.
                          </p>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </section>
        </div>
        <CustomListForm />
      </div>

      <GuaranteeBadge />
      {/*      <section className="w-full h-36 bg-grey pt-12 pb-24">
        <h2 className="text-center text-3xl uppercase track-wider">
          Frequently Asked Question
        </h2>
        <div>
          <ul className="">
            {faq.map(el => (
              <li className="mx-10 my-8 divide-y-2 divide-gray-400">
                <h3 className="text-xl m-1">{el.q}</h3>
                <p className="italic m-0 pt-2 mb-4 pl-4">{el.a}</p>
              </li>
            ))}
          </ul>
        </div>
      </section> */}
    </Layout>
  )
}

export default CustomList
