import React, { useState } from "react"
import { navigate } from "gatsby"
import { useForm } from "react-hook-form"
import instagram from "../../logos/instagram.svg"

function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}

const CustomListForm = ({ className }) => {
  const { register, handleSubmit, watch, errors, getValues } = useForm()
  const [showEmail, toggleEmail] = useState(false)

  const onSubmit = subData => {
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": "custom-list",
        ...subData,
      }),
    })
      .then(() => navigate("/success?q=custom-list/"))
      .catch(error => console.error("error:", error))
  }

  const onSubmitOld = data => console.log("data", data)

  const [totalPrice, updatePrice] = useState(0)

  const [deepDivePrice, setDeepDivePrice] = useState(0)
  const [listTypePrice, setListTypePrice] = useState(89)
  const [additionalDataPrice, setAdditionalDataPrice] = useState(0)

  const [showData, toggleData] = useState(false)

  const deepDive = watch("deepDive")
  const additionalData = watch("additionalData")

  useState(() => {
    console.log("size changed", listTypePrice)
  }, [listTypePrice])

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      name="custom-list"
      data-netlify="true"
      style={{ border: "1px solid #eaecee" }}
      className="text-left bg-white w-2/3 mx-auto divide-y divide-gray-400 px-8 pb-8"
    >
      <div className=" py-2">
        <img
          title="Instagram"
          src={instagram}
          className="mx-auto my-4 h-10 hover:opacity-75"
        />
        <h2 className="text-center">Your Instagram List</h2>
      </div>
      <div className="w-full py-4">
        <label className="uppercase block tracking-wide text-gray-700 text-md font-bold mt-2">
          Platform
          <span className="ml-2 text-sm lowercase font-light tracking-normal">
            We provide only custom instagram lists (for now)
          </span>
        </label>
        <div className="block relative w-full relative">
          <select
            {...register("platform", { disabled: true })}
            className="block w-1/3 cursor-not-allowed appearance-none bg-gray-300 border border-gray-400 text-gray-700 px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline"
          >
            <option value="instagram">Instagram</option>
          </select>
          <div className="hidden pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
            <svg
              className="fill-current h-4 w-4"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
            >
              <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
            </svg>
          </div>
        </div>
      </div>
      <div className="w-1/3 py-4">
        <label className="uppercase block tracking-wide text-gray-700 text-md font-bold mt-2">
          Influencer category
        </label>
        <div className="block w-full relative">
          <select
            {...register("category")}
            className="block w-full appearance-none cursor-pointer bg-white border border-gray-400 hover:border-gray-500 px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline"
          >
            <option value="any">Any</option>
            <option value="fashion">Fashion</option>
            <option value="Gaming">Gaming</option>
            <option value="Health & Wellness">Health & Wellness</option>
            <option value="Food & Bevarage">Food & Bevarage</option>
            <option value="Lifestyle">Lifestyle</option>
            <option value="Travel">Travel</option>
            <option value="Makeup & Skincare">Makeup & Skincare</option>
            <option value="Education & Courses">Education & Courses</option>
            <option value="Sports & Fitness">Sports & Fitness</option>
            <option value="Stocks & Crypto">Stocks & Crypto</option>
            <option value="Pets & Animals">Pets & Animals</option>
            <option value="Business & entrepreneurship">
              Business & Entrepreneurship
            </option>
          </select>
          <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
            <svg
              className="fill-current h-4 w-4"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
            >
              <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
            </svg>
          </div>
        </div>
      </div>
      <div className="w-full py-2">
        <div className="pr-4 py-2">
          <label
            title="Any subcategory within a category."
            className="uppercase flex items-center tracking-wide text-gray-700 text-md font-bold mt-2"
          >
            Deep dive{" "}
            <svg
              className="fill-current h-4 w-4 text-gray-700  ml-2"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
            >
              <path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z" />
            </svg>
            <span className="ml-2">(+$20)</span>
          </label>
          <div className="flex">
            <input
              type="checkbox"
              className="mt-2 h-4 w-4 cursor-pointer leading-tight"
              {...register("deepDive")}
              onChange={e => {
                deepDivePrice === 0 ? setDeepDivePrice(20) : setDeepDivePrice(0)
              }}
            />
            {deepDivePrice > 0 && (
              <div className="ml-2">
                <input
                  className="block appearance-none bg-white border border-gray-400 hover:border-gray-500 px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline"
                  type="text"
                  placeholder={`In what niche?`}
                  {...register("niche", {
                    validate: {
                      required: value => {
                        if (!value && getValues("deepDive"))
                          return "Required when deep dive is checked"
                        return true
                      },
                    },
                  })}
                />
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="w-full py-4">
        <div className="block">
          <label className="uppercase block tracking-wide text-gray-700 text-md font-bold mt-2">
            Country{" "}
          </label>
          <span className="text-gray-600 text-sm">
            If this field is empty we will select a mix of North American and
            European influencers.
          </span>
        </div>
        <input
          type="text"
          className="block appearance-none w-1/3 bg-white border border-gray-400 hover:border-gray-500 px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline"
          placeholder="Country or continent"
          {...register("country")}
        />
      </div>
      <div className="py-4 w-1/3">
        <div className="block">
          <label
            title="Micro influencers have between 1000 & 1.000.000 followers"
            className="uppercase flex items-center block tracking-wide text-gray-700 text-md font-bold mt-2"
          >
            List size
            <svg
              className="fill-current h-4 w-4 text-gray-700  ml-2"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
            >
              <path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z" />
            </svg>
          </label>
          <span className="text-gray-600 text-sm">
            How many{" "}
            <span title="Between 1000 & 1.000.000 followers">influencers</span>{" "}
            do you want in your list.
          </span>
        </div>
        <div className="relative">
          <select
            className="block w-full appearance-none cursor-pointer bg-white border border-gray-400 hover:border-gray-500 px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline"
            {...register("size", {
              required: true,
            })}
            onChange={e => setListTypePrice(parseInt(e.target.value))}
          >
            <option name="500 influe" value={89}>
              500 Micro Influencers ($89)
            </option>
            <option value={129}>1000 Micro Influencers ($129)</option>
            <option value={219}>2000 Micro Influencers ($219)</option>
          </select>
          <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
            <svg
              className="fill-current h-4 w-4"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
            >
              <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
            </svg>
          </div>
        </div>
      </div>
      <div>
        <h2 className="text-center mt-6 uppercase tracking-wide text-gray-800 ">
          Data
        </h2>
        <div>
          <p className="text-center">
            This list includes <code className="px-1">14</code>
            <span className="underline" title="The columns on your data sheet.">
              data fields
            </span>{" "}
            by default.
          </p>
          <p className="text-center text-gray-900 text-sm mb-0">
            <span
              className="cursor-pointer"
              onClick={() => toggleData(!showData)}
            >
              {showData ? `hide` : `show`}
            </span>
          </p>
          <div className={`${showData ? `` : `hidden`}`}>
            <div className="grid grid-cols-4 grid-rows-4 gap-2 my-4">
              <div
                title="The influencers id/handle on that platform"
                className="bg-gray-200 flex items-center justify-between text-sm border border-gray-200 text-gray-800 py-1 px-2 pr-4 rounded leading-tight"
              >
                Username{" "}
                <span>
                  <svg
                    className="fill-current h-4 w-4 text-gray-700  ml-2"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                  >
                    <path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z" />
                  </svg>
                </span>
              </div>
              <div
                title="Clickable link to their profile"
                className="bg-gray-200 flex items-center justify-between text-sm border border-gray-200 text-gray-800 py-1 px-2 pr-4 rounded leading-tight"
              >
                Link to profile
                <span>
                  <svg
                    className="fill-current h-4 w-4 text-gray-700  ml-2"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                  >
                    <path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z" />
                  </svg>
                </span>
              </div>
              <div
                title="How many users are following the influencer"
                className="bg-gray-200 flex items-center justify-between text-sm border border-gray-200 text-gray-800 py-1 px-2 pr-4 rounded leading-tight"
              >
                Follower count
                <span>
                  <svg
                    className="fill-current h-4 w-4 text-gray-700  ml-2"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                  >
                    <path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z" />
                  </svg>
                </span>
              </div>
              <div
                title="How many users the influencer is following"
                className="bg-gray-200 flex items-center justify-between text-sm border border-gray-200 text-gray-800 py-1 px-2 pr-4 rounded leading-tight"
              >
                Following count{" "}
                <span>
                  <svg
                    className="fill-current h-4 w-4 text-gray-700  ml-2"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                  >
                    <path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z" />
                  </svg>
                </span>
              </div>
              <div
                title="How many posts they have uploaded to the platform"
                className="bg-gray-200 flex items-center justify-between text-sm border border-gray-200 text-gray-800 py-1 px-2 pr-4 rounded leading-tight"
              >
                Total posts
                <span>
                  <svg
                    className="fill-current h-4 w-4 text-gray-700  ml-2"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                  >
                    <path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z" />
                  </svg>
                </span>
              </div>
              <div
                title="How many followers they have compared to following"
                className="bg-gray-200 flex items-center justify-between text-sm border border-gray-200 text-gray-800 py-1 px-2 pr-4 rounded leading-tight"
              >
                Follower ratio{" "}
                <span>
                  <svg
                    className="fill-current h-4 w-4 text-gray-700  ml-2"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                  >
                    <path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z" />
                  </svg>
                </span>
              </div>
              <div
                title="In what category they are actively posting"
                className="bg-gray-200 flex items-center justify-between text-sm border border-gray-200 text-gray-800 py-1 px-2 pr-4 rounded leading-tight"
              >
                Category{" "}
                <span>
                  <svg
                    className="fill-current h-4 w-4 text-gray-700  ml-2"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                  >
                    <path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z" />
                  </svg>
                </span>
              </div>
              <div
                title="The language they use in their posts"
                className="bg-gray-200 flex items-center justify-between text-sm border border-gray-200 text-gray-800 py-1 px-2 pr-4 rounded leading-tight"
              >
                Language{" "}
                <span>
                  <svg
                    className="fill-current h-4 w-4 text-gray-700  ml-2"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                  >
                    <path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z" />
                  </svg>
                </span>
              </div>
              <div
                title="The country they are living in"
                className="bg-gray-200 flex items-center justify-between text-sm border border-gray-200 text-gray-800 py-1 px-2 pr-4 rounded leading-tight"
              >
                Country{" "}
                <span>
                  <svg
                    className="fill-current h-4 w-4 text-gray-700  ml-2"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                  >
                    <path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z" />
                  </svg>
                </span>
              </div>
              <div
                title="Percentage of engagement on each post"
                className="bg-gray-200 flex items-center justify-between text-sm border border-gray-200 text-gray-800 py-1 px-2 pr-4 rounded leading-tight"
              >
                Engagement rate{" "}
                <span>
                  <svg
                    className="fill-current h-4 w-4 text-gray-700  ml-2"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                  >
                    <path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z" />
                  </svg>
                </span>
              </div>
              <div
                title="How many likes they usually recieve on their posts"
                className="bg-gray-200 flex items-center justify-between text-sm border border-gray-200 text-gray-800 py-1 px-2 pr-4 rounded leading-tight"
              >
                Average likes{" "}
                <span>
                  <svg
                    className="fill-current h-4 w-4 text-gray-700  ml-2"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                  >
                    <path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z" />
                  </svg>
                </span>
              </div>
              <div
                title="How many comments they usually recieve on their posts"
                className="bg-gray-200 flex items-center justify-between text-sm border border-gray-200 text-gray-800 py-1 px-2 pr-4 rounded leading-tight"
              >
                Average comments{" "}
                <span>
                  <svg
                    className="fill-current h-4 w-4 text-gray-700  ml-2"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                  >
                    <path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z" />
                  </svg>
                </span>
              </div>
              <div
                title="How many comments they recieve for each like"
                className="bg-gray-200 flex items-center justify-between text-sm border border-gray-200 text-gray-800 py-1 px-2 pr-4 rounded leading-tight"
              >
                Like/Comment ratio{" "}
                <span>
                  <svg
                    className="fill-current h-4 w-4 text-gray-700  ml-2"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                  >
                    <path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z" />
                  </svg>
                </span>
              </div>
              <div
                title="Collection of hashtags that they usually use on their posts"
                className="bg-gray-200 flex items-center justify-between text-sm border border-gray-200 text-gray-800 py-1 px-2 pr-4 rounded leading-tight"
              >
                Hashtags{" "}
                <span>
                  <svg
                    className="fill-current h-4 w-4 text-gray-700  ml-2"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                  >
                    <path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z" />
                  </svg>
                </span>
              </div>
              <div
                title="How recent our data of the influencer is"
                className="bg-gray-200 flex items-center justify-between text-sm border border-gray-200 text-gray-800 py-1 px-2 pr-4 rounded leading-tight"
              >
                Data age{" "}
                <span>
                  <svg
                    className="fill-current h-4 w-4 text-gray-700  ml-2"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                  >
                    <path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z" />
                  </svg>
                </span>
              </div>
            </div>
          </div>
          <p className="mx-auto">
            <svg
              className="fill-current cursor-pointer mx-auto h-4 w-4"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              onClick={() => toggleData(!showData)}
            >
              <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
            </svg>
          </p>
        </div>
        <div className="my-4">
          <label className="uppercase tracking-wide text-gray-700 text-sm font-bold mt-2">
            Include custom data fields (+$10)
          </label>
          <input
            type="checkbox"
            className="ml-2 cursor-pointer leading-tight"
            {...register("additionalData")}
            onChange={e => {
              additionalDataPrice === 0
                ? setAdditionalDataPrice(10)
                : setAdditionalDataPrice(0)
            }}
          />
          {additionalDataPrice > 0 && (
            <div className="my-2">
              <label className="uppercase tracking-wide text-gray-700 text-xs font-bold mt-2">
                Field name
              </label>
              <input
                type="text"
                className="block appearance-none bg-white border border-gray-400 hover:border-gray-500 px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline"
                {...register("data-field")}
                placeholder={`What is it you gonna track`}
              />
              <label className="uppercase tracking-wide text-gray-700 text-xs font-bold mt-2">
                Describe your field{" "}
                <span className="ml-2 text-sm lowercase font-light tracking-normal">
                  This will help us better understand you needs.
                </span>
              </label>
              <textarea
                {...register("data-description")}
                className="block w-1/3 appearance-none bg-white text-sm border border-gray-400 hover:border-gray-500 px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline"
                placeholder={`This field will help me measure X.`}
              ></textarea>
            </div>
          )}
        </div>
      </div>
      <div className="my-4">
        <label className="uppercase block tracking-wide text-gray-700 text-md font-bold mt-2">
          Additional information
        </label>
        <textarea
          {...register("additional-information")}
          className="block w-2/3 h-24 appearance-none bg-white text-sm border border-gray-400 hover:border-gray-500 px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline"
          placeholder={`Anything you think we should know.`}
        ></textarea>
      </div>
      <div className="my-4">
        <div>
          <p className="uppercase block tracking-wide text-gray-800 text-md font-bold mt-2">
            Estimated price{" "}
            <span
              title="Based on if we find all the data you require."
              className="text-2xl mx-2 text-center text-green"
            >
              ~${listTypePrice + deepDivePrice + additionalDataPrice}
            </span>
            <span className="text-gray-600 lowercase text-sm font-thinner">
              (excluding tax)
            </span>
          </p>
        </div>
        {!showEmail && (
          <span
            onClick={e => toggleEmail(true)}
            className="block bg-light-blue text-white text-center font-semibold font-sans lg:w-64 p-3 rounded-lg mx-auto mt-12 hover:shadow-xl cursor-pointer"
          >
            Fill In E-mail
          </span>
        )}
      </div>
      <div className={`${showEmail ? `my-8` : `hidden`}`}>
        <div className="w-2/3 flex">
          <div className="block w-1/2 pr-2">
            <label className="uppercase block tracking-wide text-gray-700 text-sm font-bold mt-2">
              First Name{" "}
            </label>
            <input
              type="text"
              placeholder={`Thor`}
              className="block appearance-none w-full bg-white border border-gray-400 hover:border-gray-500 px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline"
              {...register("first-name", { required: true })}
            />
          </div>
          <div className="block w-1/2 pl-2">
            <label className="uppercase block tracking-wide text-gray-700 text-sm font-bold mt-2">
              Last Name{" "}
            </label>
            <input
              type="text"
              placeholder={`Odinson`}
              className="block appearance-none w-full bg-white border border-gray-400 hover:border-gray-500 px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline"
              {...register("last-name", { required: true })}
            />
          </div>
        </div>
        <div className="w-full py-4">
          <div className="block">
            <label className="uppercase block tracking-wide text-gray-700 text-sm font-bold mt-2">
              E-mail{" "}
            </label>
          </div>
          <input
            type="email"
            placeholder={`thor@godofthunder.com`}
            className="block appearance-none w-1/3 bg-white border border-gray-400 hover:border-gray-500 px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline"
            {...register("email", { required: true })}
          />
        </div>
        <button
          type="submit"
          className="block bg-dark-green text-white text-center font-semibold font-sans lg:w-64 p-3 rounded-lg mx-auto mt-12 hover:shadow-xl cursor-pointer"
        >
          Place Buy Order
        </button>
      </div>
    </form>
  )
}

export default CustomListForm
